
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const errors = ref<Array<string>>([]);
    const store = useStore();
    const firstname = ref('');
    const lastname = ref('');
    const company = ref('');
    const email = ref('');
    const telephone = ref('');
    const interest = ref(0);
    const branchsolution = ref(0);
    const text = ref('');
    const customsolution = ref('');

    const sending = ref(false);
    const sent = ref(false);

    const interests = ['Bitte wählen ...', 'Werbung in der Tageszeitung', 'Online-Werbung', 'Themenschwerpunkte', 'Branchenlösungen', 'Sonstiges'];
    const solutions = ['Bitte wählen ...', 'Bildung & Karriere', 'Bauen & Wohnen', 'Moblität', 'Reise', 'Gesundheit', 'Sonstiges'];

    const send = () => {
      if (sending.value) return false;

      errors.value = [];

      if (firstname.value == '') {
        errors.value.push('Bitte geben Sie einen Vornamen an');
      }

      if (lastname.value == '') {
        errors.value.push('Bitte geben Sie einen Nachnamen an');
      }

      if (company.value == '') {
        errors.value.push('Bitte geben Sie eine Firma an');
      }

      if (email.value == '') {
        errors.value.push('Bitte geben Sie eine E-Mail-Adresse an');
      }

      if (telephone.value == '') {
        errors.value.push('Bitte geben Sie eine Telefonnummer an');
      }

      if (interest.value == 0) {
        errors.value.push('Bitte geben Sie ein Interesse an');
      }

      if (branchsolution.value == 0 && interest.value == 4) {
        errors.value.push('Bitte geben Sie eine Branchenlösung an');
      }

      if (branchsolution.value == 6 && interest.value == 4 && customsolution.value == '') {
        errors.value.push('Bitte geben Sie eine Branchenlösung an');
      }

      if (errors.value.length <= 0) {
        sending.value = true;
        store
          .dispatch('sendEmail', {
            firstname: firstname.value,
            lastname: lastname.value,
            company: company.value,
            email: email.value,
            telephone: telephone.value,
            interest: interests[interest.value],
            branchsolution: branchsolution.value < 6 ? solutions[branchsolution.value] : customsolution.value,
            text: text.value,
          })
          .then((res: any) => {
            sending.value = false;
            sent.value = true;
          });
      }
    };

    return {
      firstname,
      lastname,
      company,
      email,
      telephone,
      interest,
      branchsolution,
      text,
      interests,
      solutions,
      customsolution,
      errors,
      send,
      sent,
    };
  },
});
