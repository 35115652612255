<style lang="scss">
.kontakte-formular {
  h2 {
    border-left: 4px solid $red;
    padding: 5px 15px 7px 15px;
    margin: 20px 0;
    strong {
      text-transform: none;
    }
  }

  .top-header {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
  }
  .form-group {
    //border:1px solid red;
    margin-bottom: 15px;
    .label-input {
      margin-bottom: 5px;
      display: block;
      font-size: 14px;
      letter-spacing: 1px;
    }
    input,
    textarea {
      display: block;
      width: 100%;
      border-radius: 0 !important;
      border: 1px solid #ccc;
      padding: 0.7em 0.8em;
      outline: none;
      font-size: 100%;
      letter-spacing: 1px;
      option {
        letter-spacing: 1px;
      }
      &:focus {
        border-radius: 0 !important;
        border: 1px solid $blue;
        box-shadow: none;
      }
    }

    textarea {
      min-height: 180px;
    }
  }

  .form-group-v2 {
    border: 1px solid blue;
  }

  .form-select {
    border: 1px solid #ccc;
    width: 100%;
    padding: 0.7em 0.8em;
    padding-right: 20px;
    background-position-x: 25px;
    &:focus,
    &.active,
    &:focus-visible {
      outline: none;
      border-radius: 0;
    }
  }

  .btn-formular {
    border: 0;
    display: inline-block;
    padding: 20px 35px;
    line-height: 1;
    background: $blue-darker;
    color: #fff;
    letter-spacing: 1px;
    &:active,
    &:focus {
      border: 0;
      outline: none;
    }
    @media (max-width: $width-lg) {
      display: block;
      width: 100%;
    }
    &:hover {
      background: $blue-light;
    }
  }
  .success-msg {
    font-family: $KleineSans-Bold;
    font-size: 140%;
    text-align: center;
    padding: 20px;
  }
  .error-box {
    margin-top: 10px;
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        text-align: center;
        margin-top: 3px;
        color: $red;
      }
    }
  }
}
</style>

<template>
  <div class="kontakte-formular content">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <h1>Sie haben eine Frage oder interessieren sich für eine bestimmte Kommunikationslösung?</h1>
          <h2>Jetzt Ihre Herausforderung oder Anfrage formulieren! <span class="breaker"></span> Wir setzen uns gerne mit Ihnen in Verbindung.</h2>
        </div>
        <div class="col-lg-12">
          <div class="row justify-content-center align-self-center bg-blue-light highlight-box highlight-box-md margin-tb-m">
            <div class="col-md-6 d-flex align-self-center">
              <p>Geht es um eine Kleinanzeige? <span class="breaker"></span>Dann testen Sie unser praktisches Selfservice-Tool. <span class="breaker"></span> Was immer Sie brauchen, wir sind für Sie da!</p>
            </div>
            <div class="col-md-6 d-flex align-self-center justify-content-end">
              <div class="btn-std btn-bg-blue-dark">
                <a href="https://selfservice.kleinezeitung.at/webstore/" target="_blank">Zum Selfservice-Tool</a>
              </div>
            </div>
          </div>
        </div>
        <!-- -->

        <!--<div class="col-lg-10 margin-tb-s">
          <p>
          Wir freuen uns darauf! 
          </p>
        </div>-->
        <div class="col-lg-10 margin-b-l margin-t-s" v-if="!sent">
          <!-- -->
          <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <div class="label-input">Vorname *</div>
                <input type="text" required v-model="firstname" />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <div class="label-input">Nachname *</div>
                <input type="text" required v-model="lastname" />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <div class="label-input">Unternehmen *</div>
                <input type="text" required v-model="company" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <div class="label-input">E-Mail-Adresse *</div>
                <input type="email" required v-model="email" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <div class="label-input">Telefonnummer *</div>
                <input type="text" required v-model="telephone" />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <div class="label-input">Ich interessiere mich besonders für</div>
                <select class="form-select" v-model="interest">
                  <option :value="index" :key="index" v-for="(interest, index) of interests">{{ interest }}</option>
                </select>
              </div>
            </div>

            <div class="col-lg-12" v-if="interest == 4">
              <div class="form-group">
                <div class="label-input">Branchenlösung</div>
                <select class="form-select" v-model="branchsolution">
                  <option :value="index" :key="index" v-for="(solution, index) of solutions">{{ solution }}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-12" v-if="branchsolution == 6">
              <div class="form-group">
                <div class="label-input">Branchenlösung // Sonstiges</div>
                <input type="text" v-model="customsolution" />
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <div class="label-input">Möchten Sie uns noch etwas mitteilen?</div>
                <textarea v-model="text"></textarea>
              </div>
            </div>

            <div class="col-lg-12">
              <a href="https://www.kleinezeitung.at/service/unternehmen/impressum/5104679/Datenschutz_Datenschutzinformation-Werbemarkt" target="_blank" class="text-link-std">Wir möchten Sie auf unsere Datenschutzerklärung aufmerksam machen. <strong>Hier klicken</strong></a>
            </div>

            <div class="col-lg-4 col-md-10 margin-t-s">
              <button class="btn-formular" @click="send">Anfrage absenden</button>
            </div>
          </div>

          <div class="row justify-content-center error-box" v-if="errors.length > 0">
            <div class="col-lg-12 col-md-10 text-center">
              <ul>
                <li :key="error" v-for="error of errors">{{ error }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-10 margin-b-l margin-t-s" v-if="sent">
          <div class="success-msg">
            Vielen Dank für Ihre Anfrage. Unser Service-Team kümmert sich umgehend darum. In Kürze wird sich eine Mitarbeiterin bzw. ein Mitarbeiter bei Ihnen melden.
          </div>
        </div>
        <!-- -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const errors = ref<Array<string>>([]);
    const store = useStore();
    const firstname = ref('');
    const lastname = ref('');
    const company = ref('');
    const email = ref('');
    const telephone = ref('');
    const interest = ref(0);
    const branchsolution = ref(0);
    const text = ref('');
    const customsolution = ref('');

    const sending = ref(false);
    const sent = ref(false);

    const interests = ['Bitte wählen ...', 'Werbung in der Tageszeitung', 'Online-Werbung', 'Themenschwerpunkte', 'Branchenlösungen', 'Sonstiges'];
    const solutions = ['Bitte wählen ...', 'Bildung & Karriere', 'Bauen & Wohnen', 'Moblität', 'Reise', 'Gesundheit', 'Sonstiges'];

    const send = () => {
      if (sending.value) return false;

      errors.value = [];

      if (firstname.value == '') {
        errors.value.push('Bitte geben Sie einen Vornamen an');
      }

      if (lastname.value == '') {
        errors.value.push('Bitte geben Sie einen Nachnamen an');
      }

      if (company.value == '') {
        errors.value.push('Bitte geben Sie eine Firma an');
      }

      if (email.value == '') {
        errors.value.push('Bitte geben Sie eine E-Mail-Adresse an');
      }

      if (telephone.value == '') {
        errors.value.push('Bitte geben Sie eine Telefonnummer an');
      }

      if (interest.value == 0) {
        errors.value.push('Bitte geben Sie ein Interesse an');
      }

      if (branchsolution.value == 0 && interest.value == 4) {
        errors.value.push('Bitte geben Sie eine Branchenlösung an');
      }

      if (branchsolution.value == 6 && interest.value == 4 && customsolution.value == '') {
        errors.value.push('Bitte geben Sie eine Branchenlösung an');
      }

      if (errors.value.length <= 0) {
        sending.value = true;
        store
          .dispatch('sendEmail', {
            firstname: firstname.value,
            lastname: lastname.value,
            company: company.value,
            email: email.value,
            telephone: telephone.value,
            interest: interests[interest.value],
            branchsolution: branchsolution.value < 6 ? solutions[branchsolution.value] : customsolution.value,
            text: text.value,
          })
          .then((res: any) => {
            sending.value = false;
            sent.value = true;
          });
      }
    };

    return {
      firstname,
      lastname,
      company,
      email,
      telephone,
      interest,
      branchsolution,
      text,
      interests,
      solutions,
      customsolution,
      errors,
      send,
      sent,
    };
  },
});
</script>
